import React, { useEffect, useRef } from 'react';
import "./Bug.scss"
import {
    AnimationAction,
    AnimationClip,
    AnimationMixer,
    Mesh, MeshBasicMaterial,
    MeshMatcapMaterial, Object3D,
    PerspectiveCamera, RepeatWrapping,
    Scene, TextureLoader, Vector3,
    WebGLRenderer
} from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const Bug = () => {

    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (!canvasRef.current) return;

        // Инит
        const scene = new Scene();
        const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new WebGLRenderer({ canvas: canvasRef.current, alpha: true, antialias: true });
        let bug: Object3D | null = null;
        let initPosition: Vector3 | null = null;
        let mixer: AnimationMixer | null = null;
        let clips = [];
        let clip: AnimationClip | null = null;
        let action: AnimationAction | null = null;

        // Загрузка модели
        (async () => {

            // Модель
            const loader = new GLTFLoader();
            const loadBugModel = await loader.loadAsync("/models/bug1.glb");

            bug = loadBugModel.scene;
            bug.rotation.x = Math.PI / 2;

            // Обычная тектура
            const textureLoader = new TextureLoader();
            const baseMap = await textureLoader.loadAsync('/textures/baseMap.png');
            baseMap.flipY = false;
            baseMap.wrapS = RepeatWrapping;
            baseMap.wrapT = RepeatWrapping;

            //Текстура нормалей
            const normalMap = await textureLoader.loadAsync("/textures/normalMap.png");
            normalMap.flipY = false;
            normalMap.wrapS = RepeatWrapping;
            normalMap.wrapT = RepeatWrapping;


            // МатКап
            const matCapMap = await textureLoader.loadAsync("/textures/matCap.png");
            matCapMap.flipY = false;
            matCapMap.wrapS = RepeatWrapping;
            matCapMap.wrapT = RepeatWrapping;

            // Чб маткап ноги
            const matCapMapBlack = await textureLoader.loadAsync("/textures/matCapBlack.png");
            matCapMapBlack.flipY = false;
            matCapMapBlack.wrapS = RepeatWrapping;
            matCapMapBlack.wrapT = RepeatWrapping;

            // Чб маткап глаза
            const eyesMatCap = await textureLoader.loadAsync("/textures/eyesMatCap.png");
            matCapMapBlack.flipY = false;
            matCapMapBlack.wrapS = RepeatWrapping;
            matCapMapBlack.wrapT = RepeatWrapping;

            // Лого маткап
            const logoMatCap = await textureLoader.loadAsync("/textures/logoMatCap.png");
            matCapMapBlack.flipY = false;
            matCapMapBlack.wrapS = RepeatWrapping;
            matCapMapBlack.wrapT = RepeatWrapping;

            //Текстура для волос
            const hairTexture = await textureLoader.loadAsync("/textures/hairTexture.png");
            hairTexture.flipY = false;
            hairTexture.wrapS = RepeatWrapping;
            hairTexture.wrapT = RepeatWrapping;

            // Альфамап для волос
            const alphaMap = await textureLoader.loadAsync("/textures/alphaMap1.png");
            alphaMap.flipY = false;
            alphaMap.wrapS = RepeatWrapping;
            alphaMap.wrapT = RepeatWrapping;


            const body = bug?.getObjectByName("Body_1") as Mesh;
            const legs = bug?.getObjectByName("Legs") as Mesh;
            const logo = bug?.getObjectByName("Logo") as Mesh;
            const eyes = bug?.getObjectByName('Eyes') as Mesh;

            body.material = new MeshMatcapMaterial({ normalMap: normalMap, matcap: matCapMap })
            legs.material = new MeshMatcapMaterial({ matcap: matCapMapBlack, normalMap: normalMap });
            logo.material = new MeshMatcapMaterial({ matcap: logoMatCap })
            eyes.material = new MeshMatcapMaterial({ matcap: eyesMatCap })

            mixer = new AnimationMixer(body);
            clips = loadBugModel.animations;
            clip = AnimationClip.findByName(clips, "Action");
            action = mixer.clipAction(clip);

            // duration = 25s
            // stop =
            action.play();

            bug.traverse(m => {
                if (m instanceof Mesh) {
                    m.frustumCulled = false;
                    if (m.name[0] === "H") {
                        m.material = new MeshBasicMaterial({ alphaMap: alphaMap, map: hairTexture, transparent: true })
                    }
                }
            })

            initPosition = new Vector3(-5.1, -7, 0);

            if (window.innerWidth > 700 && window.innerWidth < 1000 ) {
                initPosition.x = -5.5;
                initPosition.y = -7.5;
            }

            if (window.innerWidth > 1000 && window.innerWidth < 1200 ) {
                initPosition.x = -6.3;
                initPosition.y = -8.5;
            }

            if (window.innerWidth > 1200) {
                initPosition.x -= window.innerWidth / 800;
                initPosition.y -= window.innerWidth / 800;
            }


            //Установка позиции жука
            bug!.position.set(initPosition.x, initPosition.y, initPosition.z);
            bug!.rotation.y = -Math.PI / 5;

            if (window.innerWidth < 769) {
                bug!.scale.setScalar(0.8 + window.innerWidth / 10000);

            }
            else bug!.scale.setScalar(0.8 + window.innerWidth / 6000);

            scene.add(bug);
        })();

        camera.position.z = 5;

        // Ресайз
        const resize = () => {
            if (canvasRef.current) {
                const w = canvasRef.current.clientWidth * window.devicePixelRatio;
                const h = canvasRef.current.clientHeight * window.devicePixelRatio;
                canvasRef.current.width = w;
                canvasRef.current.height = h;
                renderer.setSize(w, h, false);
                camera.aspect = w / h;
                camera.updateProjectionMatrix();
            }

            if (window.innerWidth < 1000 && bug) {
                // bug.scale.setScalar(0.8);
            }
        }
        window.addEventListener('resize', resize);
        resize();


        // Анимации
        let raf = 0;
        let prevTime = 0;
        const animate = (time: number) => {


            raf = requestAnimationFrame(animate);
            const delta = (time - prevTime) / 16.666;
            prevTime = time;


            if (mixer && action && clip) {
                mixer.update(delta * 0.0055);
                if (mixer.time > 2 && mixer.time < 2.2) {
                    // action.reset();
                }
                if (mixer.time > 2.2) {
                    // action.paused = false;
                }
            }

            if (bug && initPosition) {

                if (bug.position.y > 8) {
                    bug.position.set(initPosition.x, initPosition.y, initPosition.z);
                }

            }

            renderer.render(scene, camera);
        }
        animate(performance.now());

        return () => {
            cancelAnimationFrame(raf);
            window.removeEventListener('resize', resize)
        }

    }, [canvasRef])



    return (
        <canvas className={'bug'} ref={canvasRef}></canvas>
    );
};

export default Bug;