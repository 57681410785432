import React, {useEffect, useRef, useState} from 'react';
import "./Audio.scss"

interface AudioProps{
    playing: boolean | (() => void),
    toggle: boolean | (() => void)
}

const Audio = ({playing, toggle } : AudioProps) => {
    const [onHover, setOnHover] = useState<boolean>(false);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    // Канвас для активного состояния музыки
    useEffect(() => {
            if (canvasRef.current){
                const canvas = canvasRef.current;
                const ctx = canvasRef.current.getContext('2d');
                let raf = 0;
                canvas.width = 51 * window.devicePixelRatio;
                canvas.height = 12 * window.devicePixelRatio;

                if (ctx){
                    const draw = (time: number) => {

                        // Установка размера канваса
                        ctx.resetTransform();
                        ctx.scale(window.devicePixelRatio, window.devicePixelRatio);

                        // Очищение канваса на кадре
                            ctx.clearRect(0, 0, 51, 12);

                        // Стили линии
                        ctx.strokeStyle = onHover ? "#FF30F7" : '#fff';
                        ctx.lineWidth = 2;

                        ctx.beginPath();
                        if (window.innerWidth < 768){
                            for (let i = 0; i < 40; i+= 3) {
                                const y = Math.sin(i * 0.32 + time * 0.01) * 4.5 + 5;
                                if (i === 0) {
                                    ctx.moveTo(i, y);
                                } else {
                                    ctx.lineTo(i, y);
                                }
                            }
                        }
                        else{
                            for (let i = 0; i < 51; i+= 3) {
                                const y = Math.sin(i * 0.3 + time * 0.01) * 5 + 5;
                                if (i === 0) {
                                    ctx.moveTo(i, y);
                                } else {
                                    ctx.lineTo(i, y);
                                }
                            }
                        }
                        ctx.stroke();
                    }

                    const animate = (t: number) =>{
                        raf = requestAnimationFrame(animate)
                        draw(t);
                    }

                    animate(performance.now());
                }

                return () => {
                    cancelAnimationFrame(raf);
                }
            }
        },
        [playing, onHover]
    );

    return (
        // @ts-ignore
        <div className={'audio'} onClick={toggle} onMouseEnter={ window.innerWidth > 768 ? () => setOnHover(true) : undefined}
             onMouseLeave={window.innerWidth > 768 ? () => setOnHover(false) : undefined}>
            {playing ? <canvas className={'audio-active'} ref={canvasRef}>
                        </canvas>
                : <div className={'audio-muted'}></div>}
        </div>
    );
};

export default Audio;