import React, { useEffect, useState } from 'react';
import './App.scss';
import Title from "./components/Title";
import Typography from "./components/Typography";
import Footer from "./components/Footer";
import Bug from "./components/Bug";
import Audio from "./components/Audio";
import Background from "./components/Background";
import Banner from "./components/Banner";
import { useAudio } from "./hooks/useAudio";


function App() {
    const [playing, toggle] = useAudio("/audio/ambient.mp3");
    const [isBanner, setIsBanner] = useState<boolean>(true);

    // Когда включилась музыка закрываем баннер
    useEffect(() => {
        if (playing) {
            setTimeout(() => { setIsBanner(false); }, 250)
        }
    }, [playing])

    return (
        <div className="wrapper">
            <Background />
            {isBanner ?
                <Banner playing={playing} setIsPlaying={toggle} />
                :
                <div className={'content'}>
                    <Bug />
                    <Audio playing={playing} toggle={toggle} />
                    <Title />
                    <Typography />
                    <Footer />
                </div>}
        </div>
    );
}

export default App;
