import React, {useEffect, useState} from 'react';
import "./Title.scss"

const Title = () => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const titleText = "Let's make it better"
    const arr = titleText.split(' ');

    useEffect(() => {
        setIsActive(true);
    },[])

    return (
        <div className={'title' + `${!isActive ? " active" : ""}`}>
            <h1 className={'title-text'}>{arr.map((e, index) =>
                <div key={index} className={'title-text-word'}>{e.split('').map((sym, index) => <span key={index} className={'title-text-symbol' + `${isActive ? " active-symbol" : ''}`}>{sym}</span>)}</div>)}</h1>
            <div className={'title-button'}>
                <a  href="https://t.me/rdclr_home">join us!</a>
            </div>

        </div>
    );
};

export default Title;