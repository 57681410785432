import React, {useEffect, useRef} from 'react';
import {
    Mesh, MeshMatcapMaterial,
    Object3D,
    PerspectiveCamera, RepeatWrapping,
    Scene, TextureLoader,
    WebGLRenderer
} from "three";
import "./Background.scss"
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";

const Background = () => {

    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (!canvasRef.current) return;

        // Инит
        const scene = new Scene();
        const camera = new PerspectiveCamera(80, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new WebGLRenderer({canvas: canvasRef.current, alpha: true, antialias: true});
        let opacity = 0.15;
        let bg: Object3D | null = null;
        let animatedMesh: Mesh | null = null;

        if (window.innerWidth > 768){
            opacity = 0.1;
        }


        // Загрузка модели
        (async () => {

            // Модель
            const loader = new GLTFLoader();
            const textureLoader = new TextureLoader();
            const loadBgModel = await loader.loadAsync("/models/bg.glb");

            const alphaMap  = await textureLoader.loadAsync("/textures/bgAlpha.png");
            alphaMap.flipY = false;
            alphaMap.wrapS = RepeatWrapping;
            alphaMap.wrapT = RepeatWrapping;

            const matCap  = await textureLoader.loadAsync("/textures/bgMatCap.png");
            matCap.flipY = false;
            matCap.wrapS = RepeatWrapping;
            matCap.wrapT = RepeatWrapping;


            const torusNormal  = await textureLoader.loadAsync("/textures/torusNormal.png");
            torusNormal.flipY = false;
            torusNormal.wrapS = RepeatWrapping;
            torusNormal.wrapT = RepeatWrapping;

            const sphereNormal  = await textureLoader.loadAsync("/textures/sphereNormal.png");
            sphereNormal.flipY = false;
            sphereNormal.wrapS = RepeatWrapping;
            sphereNormal.wrapT = RepeatWrapping;


            const ebalaNormal  = await textureLoader.loadAsync("/textures/ebalaNormal.png")
            ebalaNormal.flipY = false;
            ebalaNormal.wrapS = RepeatWrapping;
            ebalaNormal.wrapT = RepeatWrapping;


            bg = loadBgModel.scene;

            // Загрузка моделей
            const torus = bg.getObjectByName('Torus') as Mesh;
            const spheres = bg.getObjectByName('Spheres') as Mesh;
            const ebala = bg.getObjectByName('Ebala') as Mesh;

            // Установка материалов
            torus.material = new MeshMatcapMaterial({matcap: matCap, normalMap: torusNormal, transparent : true, opacity: opacity});
            spheres.material = new MeshMatcapMaterial({matcap: matCap, normalMap: sphereNormal, transparent : true, opacity: opacity});
            ebala.material = new MeshMatcapMaterial({matcap: matCap, transparent: true , alphaMap: alphaMap, normalMap: ebalaNormal, opacity: opacity});
            ebala.renderOrder = -20;
            animatedMesh = ebala;

            bg.position.set(-0.1,5,-75);
            scene.add(bg);

            // 2.5
            bg.scale.setScalar(5);
            bg.position.x = -20;
            bg.position.y = -10;

            bg.rotation.set(Math.PI/180 * 70, Math.PI/180 * 5  ,Math.PI/180 * -0.1);

        })();

        camera.position.z = 5;

        // Ресайз
        const resize = () => {
            if (canvasRef.current) {
                const w = canvasRef.current.clientWidth * window.devicePixelRatio;
                const h = canvasRef.current.clientHeight * window.devicePixelRatio;
                canvasRef.current.width = w;
                canvasRef.current.height = h;
                renderer.setSize(w, h, false);
                camera.aspect = w / h;
                camera.updateProjectionMatrix();
            }
        }
        window.addEventListener('resize', resize);
        resize();


        // Анимации
        let raf = 0;
        let prevTime = 0;
        const animate = (time: number) => {
            raf = requestAnimationFrame(animate);
            const delta = (time - prevTime) / 16.666;
            prevTime = time;

            if (bg && animatedMesh){
                bg.position.x = Math.sin(prevTime *  0.0005);
                bg.position.y = Math.cos(prevTime * 0.0005);
                animatedMesh.position.z = Math.sin(prevTime * 0.0002);

            }

            renderer.render(scene, camera);
        }
        animate(performance.now());

        return () => {
            cancelAnimationFrame(raf);
            window.removeEventListener('resize', resize)
        }

    }, [canvasRef])


    return (
        <div className={'bg'}>
            <canvas className={'bg-scene'} ref={canvasRef}></canvas>
        </div>
    );
};

export default Background;