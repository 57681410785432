import React, {useEffect, useState} from 'react';
import "./Typography.scss"

const Typography = () => {
    const [isActive, setIsActive] = useState<boolean>(false);

    useEffect(() => {
       setTimeout(() => {
           setIsActive(true);
       }, 300)
    })

    return (
        <div className={'typography' + `${isActive ? " active" : "" }`}>
            <h4 className={'typography-title'}>we are digggital </h4>
            <p className={'typography-text'}>Мы в своем познании настолько преисполнились, и нам этот мир абсолютно понятен, поэтому мы здесь ищем только одно — профессиональное комьюнити, которое будет продвигать digital-тусовку в Воронеже. Самое время объединяться, дружить и создавать крутые цифровые продукты. Присоединяйся! </p>
        </div>
    );
};

export default Typography;