import React from 'react';
import "./Banner.scss"
import headPhones from "../images/headphones.svg"

interface BannerProps {
    setIsPlaying: boolean | (() => void)
    playing: boolean | (() => void)
}

const Banner = (props: BannerProps) => {

    return (
        <div className={'banner' + `${props.playing ? " hidden" : ""}`}>
            <img src={headPhones} alt="" />
            <p>best experience with sound</p>
            {/*@ts-ignore*/}
            <button onClick={props.setIsPlaying}>start!</button>

        </div>
    );
};

export default Banner;